import { isMobile } from '../utils/index'
export default {
  address: '接待中心：雲林縣斗六市石榴路371號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14607.979864091509!2d120.59399398890356!3d23.747558922478717!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1a556aef3221295d!2zMjPCsDQ0JzUxLjAiTiAxMjDCsDM2JzA5LjIiRQ!5e0!3m2!1szh-TW!2stw!4v1640236354859!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/U8cVutaF9AULKETG8',
  phone: '05-557-0606',
  fbLink: 'https://www.facebook.com/445149012356856',
  fbMessage: 'https://m.me/445149012356856',
  caseName: '姮興光禾墅',
  indigatorLength: 10,

  houseInfos: [
    ['售價', '630萬元起',],
    ['地坪', '20～52坪',],
    ['建坪', '43～52坪',],
    ['樓層', '正3樓透天',],
    ['房數', '3～4房',],
    ['基地位置', '雲林縣林內鄉九芎村民權路',],
  ],

  gtmCode: ['TQPPZSD'], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '預約賞屋',
    subTitle: ''
  }
}
